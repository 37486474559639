
.footer{
    margin-bottom:15px;
}
.terms_container{
    padding: 16px 16px 34px 16px;
    border-bottom: 1px solid #bdbdbd;
}
.terms{
    font-family: "Inter Regular";
    font-size:14px;
    line-height:20px;
    display:block;
    color: #757575;
    margin-top: 24px;
}

.social_container{
    padding: 16px 16px 5px 16px;
    width: 100%;
}

.social{
    width: 70%;
    margin: 10px auto;
    display: flex;
    justify-content:space-between
}

.privacy_container{
    padding: 16px 16px 16px 16px;
    display: flex;
    justify-content:space-between;
    width:100%
}

.copyright{
    font-family: "Inter Regular";
    font-size:16px;
    color: #757575;
}

.privacy{
    font-family: "Inter Regular";
    font-size:14px;
    color: #757575;
}


@media (min-width: 768px){
    .terms{
        width: 160px
    }
    .privacy{
        width: 145px;
    }
}