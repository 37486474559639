.checkout{
    background-color: #f5f5f5;
    padding:18px 16px 24px 16px;
}
.checkout_container{
    padding-bottom: 20px;
    border-bottom: 1px solid #bdbdbd;
}

.card_container{
    display:flex;
    justify-content: space-between;
    margin-bottom: 14px;
}

.card_icon{
    margin-left: 4px;
}

.card{
    font-family: "Inter Regular";
    font-size:14px;
    color: #212121;
    line-height: 20px;
}

.name_card{
    font-family: "Inter Regular";
    font-size:14px;
    color: #424242;
    line-height: 20px;
}

.input{
    padding: 8px 16px;
    font-family: "Inter Regular";
    font-size:16px;
    color: #212121;
    border: 1px solid #bdbdbd;
}

.input_name{
    width: 100%;
    margin-top: 6px;
    border-radius: 8px;
    margin-bottom: 16px;
}

.input_number_container{
    width: 100%;
    position: relative;
}

.input_card{
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    margin-top:4px
}

.input_date{
    width: 50%;
    border-radius: 0px 0px 0px 8px;
}

.input_cvc{
    width: 100%;
    border-radius:0px 0px 8px 0px
}

.input_card_container{
    position: relative;
    width: 50%;
}

.month_cvc_container{
    display:flex
}

.image_type_card{
    position: absolute;
    top: 12px;
    right: 16px;
    width: 24px;
    height:24px
}

.image_cvc{
    position: absolute;
    top: 8px;
    right: 16px;
    width: 24px;
    height:24px
}

.submit_container{
    background-color: #ffff;
    padding:18px 24px 12px 24px;
}

@media (min-width: 1024px) {
  .submit_container {
    background-color: #ffff;
    padding: 18px 0 12px 0;
  }
}

.btn_submit{
    background: #E61E4D;
    color: #fff;
    text-align: center;
    padding:8px;
    border-radius: 8px;
    width:100%;
    font-family: "Inter Regular";
    font-size:16px;
    font-weight: bold;
    cursor: pointer;
}

.no_card_text_container{
    width: 70%;
    margin: 16px auto;
    text-align: center;
}

.no_card_text{
    font-family: "Inter Regular";
    font-size:14px;
    color: #212121;
    line-height: 20px;
    text-decoration: underline;
    cursor: pointer;
}


.error_message{
    font-family: "Inter Regular";
    font-size:14px;
    color: #e11900;
    margin-bottom: 8px;
}

.error_container{
    display: flex;
    
}

.error_icon{
    width: 24px;
    height: 24px;
    margin-right: 5px ;
}

@media (min-width: 768px){
    .checkout{
        border-radius: 8px;
    }
}


