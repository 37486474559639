.header {
    width: 100%;
    height: 64px;
    box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content:center;
    align-items:center;
    margin-bottom:3px
}

@media (min-width: 768px){
    .logo{
        width: 106px;
        height:24px
    }
}