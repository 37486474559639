.reserve{
    padding:24px;
    background:#ffffff
}

.title{
    color:#212121;
    font-family: "Poppins Regular";
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.0015em;
    margin-bottom: 16px;
    width: 203px;
}

.titleBold{
    color: #F39B87;
    font-family: 'Poppins Bold', sans-serif;
}

.text{
    color: #616161;
    font-family: "Inter Regular";
    font-size: 16px;
    line-height:24px;
    width: 320px;
}

.textSecondary{
    color: #616161;
    letter-spacing: 0.005em;
    margin-top: 24px;
    font-family: "Inter Regular";
    font-size: 12px;
    line-height: 16px;
}

.cancel{
    text-decoration:underline;
    font-family: "Inter Regular";
    color: #212121;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0125em;
    cursor: pointer;
    margin-left: 5px;
}

@media (min-width: 768px){
    .title {
        width: auto;
    }
    .text {
        width: auto;
    }
    .reserve{
        padding:24px 0px
    }
    .cancel{
        width: 180px;
    }
}