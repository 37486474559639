.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter:blur(8px);
    overflow: scroll;

  }
  
  .modal_container {
    position: relative;
    top:15vh;
    background-color: #fff;
    padding: 0px 34px;
    width: 86.7%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 999999;
  }
.body_modal{
    text-align: center;
}

.modal_icon{
    width: 54px;
    height:54px;
    margin: 37px auto 30px auto;
}

.modal_title {
    font-family: 'Poppins Bold';
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color:#212121;
    margin-bottom: 24px;
}

.modal_textBold{
    font-family: 'Inter Bold';
}

.modal_text{
    font-family: "Inter Regular";
    font-size:16px;
    color:#616161;
    line-height:24px;
    margin-bottom: 24px;
    padding:10px;
}

.modal_btn{
    background-color: #e61e4d;
    color:#fff;
    font-family: "Inter Bold";
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    text-align: center;
    padding: 8px;
    border-radius:8px;
    margin-bottom: 24px;
    cursor: pointer;
}

@media (min-width: 768px){
    .modal_container{
        width:614px
    }

    .modal_title{
        font-size: 32px;
        line-height: 48px;
        letter-spacing: 0.0025em;
    }
}